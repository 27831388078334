<!-- 商超服务费-查看详情 -->
<template>
  <div>
    <back />
    <div class="detail" v-loading="loading">
      <h3 class="title">基本信息：</h3>

      <el-row class="row">
        <el-col :span="6">
          <div class="col"><span class="txt">缴费单号:</span>{{ detail?.feeCode }}</div>
          <div class="col"><span class="txt">结算总额(元):</span>{{ detail?.settlementAmount }}</div>
        </el-col>
        <el-col :span="6">
          <div class="col"><span class="txt">商超名称:</span>{{ detail?.bizName }}</div>
          <div class="col"><span class="txt">服务费金额(元):</span>{{ detail?.feeAmount }}</div>
        </el-col>
        <el-col :span="6">
          <div class="col"><span class="txt">结算时间:</span>{{ detail?.createdTime }}</div>
          <div class="col"><span class="txt">缴费状态:</span><span :class="{ 'red': detail?.status === 0 }">{{
      getTypeText(detail?.status) }}</span></div>
        </el-col>
        <el-col :span="6">
          <div class="col"><span class="txt">结算周期:</span>{{ detail?.startDate + '~' + detail?.endDate }}</div>
          <div class="col"><span class="txt" v-if="detail?.status != 0">缴费时间:</span>{{ detail?.payTime }}</div>
        </el-col>
      </el-row>

      <h3 class="title">商品信息：</h3>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" height="500">
          <el-table-column label="序号" width="80" type="index" show-overflow-tooltip></el-table-column>
          <el-table-column show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column show-overflow-tooltip prop="categoryName" label="商品类目"></el-table-column>
          <el-table-column show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column show-overflow-tooltip prop="settlementAmount" label="结算金额(元)"></el-table-column>
          <el-table-column show-overflow-tooltip prop="feeRate" label="费率(%)"></el-table-column>
          <el-table-column show-overflow-tooltip prop="feeAmount" label="费用(元)"></el-table-column>
        </el-table>
      </div>


    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      detail: {},
      id: '',
      // 表格数据
      tableData: [],
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.loading = true;
      this.$axios.get(this.$api.getTechFeeInfo, {
        params: {
          id: this.id
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.detail = res.data.result;
          this.tableData = res.data.result.techFeeDetailVos;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    getTypeText(status) {
      switch (String(status)) {
        case '0':
          return '未缴费';
        case '1':
          return '已缴费';
        case '2':
          return '已完成';
        default:
          return '';
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.detail {
  padding: 20px;

  .title {
    margin-bottom: 25px;
  }

  .row {
    color: #707070;
  }

  .col {
    margin-bottom: 25px;
  }

  .red {
    color: red;
  }

  .txt {
    margin-right: 8px;
  }

}
</style>
